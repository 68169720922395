/* eslint-disable prefer-const */
/* tslint:disable:no-unused-variable member-ordering */

/**
 * SpaServer
 * SpaServer
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';

import { RoomLightSystem } from '../model/roomLightSystem';
import { RoomPool } from '../model/roomPool';
import { RoomSoundSystem } from '../model/roomSoundSystem';
import { RoomTV } from '../model/roomTV';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoomDeviceService {
  protected basePath = environment.apiBasePath;

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get Room lightsystem
   *
   * @param roomId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRoomLightSystem(roomId: string, observe?: 'body', reportProgress?: boolean): Observable<RoomLightSystem>;
  public getRoomLightSystem(
    roomId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RoomLightSystem>>;
  public getRoomLightSystem(
    roomId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RoomLightSystem>>;
  public getRoomLightSystem(roomId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (roomId === null || roomId === undefined) {
      throw new Error('Required parameter roomId was null or undefined when calling getRoomLightSystem.');
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<RoomLightSystem>(`${this.basePath}/room/${encodeURIComponent(String(roomId))}/light`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get Room pool
   *
   * @param roomId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRoomPool(roomId: string, observe?: 'body', reportProgress?: boolean): Observable<RoomPool>;
  public getRoomPool(
    roomId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RoomPool>>;
  public getRoomPool(roomId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoomPool>>;
  public getRoomPool(roomId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (roomId === null || roomId === undefined) {
      throw new Error('Required parameter roomId was null or undefined when calling getRoomPool.');
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<RoomPool>(`${this.basePath}/room/${encodeURIComponent(String(roomId))}/pool`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get Room soundsystem
   *
   * @param roomId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRoomSoundSystem(roomId: string, observe?: 'body', reportProgress?: boolean): Observable<RoomSoundSystem>;
  public getRoomSoundSystem(
    roomId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RoomSoundSystem>>;
  public getRoomSoundSystem(
    roomId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RoomSoundSystem>>;
  public getRoomSoundSystem(roomId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (roomId === null || roomId === undefined) {
      throw new Error('Required parameter roomId was null or undefined when calling getRoomSoundSystem.');
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<RoomSoundSystem>(`${this.basePath}/room/${encodeURIComponent(String(roomId))}/sound`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get Room tv
   *
   * @param roomId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRoomTV(roomId: string, observe?: 'body', reportProgress?: boolean): Observable<RoomTV>;
  public getRoomTV(roomId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoomTV>>;
  public getRoomTV(roomId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoomTV>>;
  public getRoomTV(roomId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (roomId === null || roomId === undefined) {
      throw new Error('Required parameter roomId was null or undefined when calling getRoomTV.');
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<RoomTV>(`${this.basePath}/room/${encodeURIComponent(String(roomId))}/tv`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Update Room lightsystem
   *
   * @param roomId
   * @param lightsystem
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRoomLightSystem(
    roomId: string,
    lightsystem: RoomLightSystem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RoomLightSystem>;
  public updateRoomLightSystem(
    roomId: string,
    lightsystem: RoomLightSystem,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RoomLightSystem>>;
  public updateRoomLightSystem(
    roomId: string,
    lightsystem: RoomLightSystem,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RoomLightSystem>>;
  public updateRoomLightSystem(
    roomId: string,
    lightsystem: RoomLightSystem,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (roomId === null || roomId === undefined) {
      throw new Error('Required parameter roomId was null or undefined when calling updateRoomLightSystem.');
    }
    if (lightsystem === null || lightsystem === undefined) {
      throw new Error('Required parameter lightsystem was null or undefined when calling updateRoomLightSystem.');
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<RoomLightSystem>(
      `${this.basePath}/room/${encodeURIComponent(String(roomId))}/light`,
      lightsystem,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update Room pool
   *
   * @param roomId
   * @param pool
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRoomPool(
    roomId: string,
    pool: RoomPool,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RoomPool>;
  public updateRoomPool(
    roomId: string,
    pool: RoomPool,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RoomPool>>;
  public updateRoomPool(
    roomId: string,
    pool: RoomPool,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RoomPool>>;
  public updateRoomPool(
    roomId: string,
    pool: RoomPool,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (roomId === null || roomId === undefined) {
      throw new Error('Required parameter roomId was null or undefined when calling updateRoomPool.');
    }
    if (pool === null || pool === undefined) {
      throw new Error('Required parameter pool was null or undefined when calling updateRoomPool.');
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<RoomPool>(`${this.basePath}/room/${encodeURIComponent(String(roomId))}/pool`, pool, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Update Room soundsysstem
   *
   * @param roomId
   * @param soundsystem
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRoomSoundSystem(
    roomId: string,
    soundsystem: RoomSoundSystem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RoomSoundSystem>;
  public updateRoomSoundSystem(
    roomId: string,
    soundsystem: RoomSoundSystem,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RoomSoundSystem>>;
  public updateRoomSoundSystem(
    roomId: string,
    soundsystem: RoomSoundSystem,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RoomSoundSystem>>;
  public updateRoomSoundSystem(
    roomId: string,
    soundsystem: RoomSoundSystem,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (roomId === null || roomId === undefined) {
      throw new Error('Required parameter roomId was null or undefined when calling updateRoomSoundSystem.');
    }
    if (soundsystem === null || soundsystem === undefined) {
      throw new Error('Required parameter soundsystem was null or undefined when calling updateRoomSoundSystem.');
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<RoomSoundSystem>(
      `${this.basePath}/room/${encodeURIComponent(String(roomId))}/sound`,
      soundsystem,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update Room tv
   *
   * @param roomId
   * @param tv
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRoomTV(roomId: string, tv: RoomTV, observe?: 'body', reportProgress?: boolean): Observable<RoomTV>;
  public updateRoomTV(
    roomId: string,
    tv: RoomTV,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RoomTV>>;
  public updateRoomTV(
    roomId: string,
    tv: RoomTV,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RoomTV>>;
  public updateRoomTV(
    roomId: string,
    tv: RoomTV,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (roomId === null || roomId === undefined) {
      throw new Error('Required parameter roomId was null or undefined when calling updateRoomTV.');
    }
    if (tv === null || tv === undefined) {
      throw new Error('Required parameter tv was null or undefined when calling updateRoomTV.');
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<RoomTV>(`${this.basePath}/room/${encodeURIComponent(String(roomId))}/tv`, tv, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}

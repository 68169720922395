import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlamourTintDirective } from './glamour-tint.directive';

@NgModule({
  declarations: [GlamourTintDirective],
  imports: [CommonModule],
  exports: [GlamourTintDirective],
})
export class GlamourTintModule {}

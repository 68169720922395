/**
 * SpaServer
 * SpaServer
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RoomSoundSystem {
  status?: RoomSoundSystemStatus;
  volume?: number;
}

export type RoomSoundSystemStatus = 'PLAY' | 'PAUSE';
export const RoomSoundSystemStatus = {
  PLAY: 'PLAY' as RoomSoundSystemStatus,
  PAUSE: 'PAUSE' as RoomSoundSystemStatus,
};

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* tslint:disable:no-unused-variable member-ordering */

/**
 * SpaServer
 * SpaServer
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { CleaningProtocol } from '../model/cleaningProtocol';
import { Room } from '../model/room';
import { RoomSession } from '../model/roomSession';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoomService {
  protected basePath = environment.apiBasePath;

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Finish Room Cleaning
   *
   * @param roomId
   * @param staffPin
   * @param notes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public finishCleaning(
    roomId: string,
    staffPin: string,
    notes?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CleaningProtocol>;
  public finishCleaning(
    roomId: string,
    staffPin: string,
    notes?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CleaningProtocol>>;
  public finishCleaning(
    roomId: string,
    staffPin: string,
    notes?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CleaningProtocol>>;
  public finishCleaning(
    roomId: string,
    staffPin: string,
    notes?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (roomId === null || roomId === undefined) {
      throw new Error('Required parameter roomId was null or undefined when calling finishCleaning.');
    }

    if (staffPin === null || staffPin === undefined) {
      throw new Error('Required parameter staffPin was null or undefined when calling finishCleaning.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let formParams: { append(param: string, value: any): void | HttpParams };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (staffPin !== undefined) {
      formParams = formParams.append('staffPin', <any>staffPin) || formParams;
    }
    if (notes !== undefined) {
      formParams = formParams.append('notes', <any>notes) || formParams;
    }

    return this.httpClient.delete<CleaningProtocol>(
      `${this.basePath}/room/${encodeURIComponent(String(roomId))}/cleaning`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Room
   *
   * @param roomId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRoom(roomId: string, observe?: 'body', reportProgress?: boolean): Observable<Room>;
  public getRoom(roomId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Room>>;
  public getRoom(roomId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Room>>;
  public getRoom(roomId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (roomId === null || roomId === undefined) {
      throw new Error('Required parameter roomId was null or undefined when calling getRoom.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<Room>(`${this.basePath}/room/${encodeURIComponent(String(roomId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get active Room session
   *
   * @param roomId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRoomActiveSession(roomId: string, observe?: 'body', reportProgress?: boolean): Observable<RoomSession>;
  public getRoomActiveSession(
    roomId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RoomSession>>;
  public getRoomActiveSession(
    roomId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RoomSession>>;
  public getRoomActiveSession(roomId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (roomId === null || roomId === undefined) {
      throw new Error('Required parameter roomId was null or undefined when calling getRoomActiveSession.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<RoomSession>(`${this.basePath}/room/${encodeURIComponent(String(roomId))}/session`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get Rooms
   *
   * @param page
   * @param pageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRooms(
    page?: number,
    pageSize?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Room>>;
  public getRooms(
    page?: number,
    pageSize?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Room>>>;
  public getRooms(
    page?: number,
    pageSize?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Room>>>;
  public getRooms(
    page?: number,
    pageSize?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<Room>>(`${this.basePath}/room`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Start Room Cleaning
   *
   * @param roomId
   * @param staffPin
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public startCleaning(
    roomId: string,
    staffPin: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CleaningProtocol>;
  public startCleaning(
    roomId: string,
    staffPin: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CleaningProtocol>>;
  public startCleaning(
    roomId: string,
    staffPin: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CleaningProtocol>>;
  public startCleaning(
    roomId: string,
    staffPin: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (roomId === null || roomId === undefined) {
      throw new Error('Required parameter roomId was null or undefined when calling startCleaning.');
    }

    if (staffPin === null || staffPin === undefined) {
      throw new Error('Required parameter staffPin was null or undefined when calling startCleaning.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/x-www-form-urlencoded'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void | HttpParams };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (staffPin !== undefined) {
      formParams = formParams.append('staffPin', <any>staffPin) || formParams;
    }

    return this.httpClient.post<CleaningProtocol>(
      `${this.basePath}/room/${encodeURIComponent(String(roomId))}/cleaning`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}

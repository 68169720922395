import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApiModule } from 'src/api';
import { ButtonModule } from 'src/components/button/button.module';
import { GlamourTintModule } from 'src/directives/glamour-tint/glamour-tint.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, ButtonModule, GlamourTintModule, ApiModule],
  bootstrap: [AppComponent],
  providers: [],
})
export class AppModule {}

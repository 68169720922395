/**
 * SpaServer
 * SpaServer
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Room } from './room';
import { RoomGuest } from './roomGuest';

export interface RoomSession {
  id?: string;
  room?: Room;
  guests?: Array<RoomGuest>;
  bookedSessionLength?: number;
  finalSessionEnd?: string;
  sessionEnd?: string;
}

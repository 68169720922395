/* eslint-disable prefer-const */
/* tslint:disable:no-unused-variable member-ordering */

/**
 * SpaServer
 * SpaServer
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { RoomGuest } from '../model/roomGuest';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CheckinCheckoutService {
  protected basePath = environment.apiBasePath;

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Checkin guest
   *
   * @param ticketCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkinGuest(ticketCode: string, observe?: 'body', reportProgress?: boolean): Observable<RoomGuest>;
  public checkinGuest(
    ticketCode: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RoomGuest>>;
  public checkinGuest(
    ticketCode: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RoomGuest>>;
  public checkinGuest(ticketCode: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (ticketCode === null || ticketCode === undefined) {
      throw new Error('Required parameter ticketCode was null or undefined when calling checkinGuest.');
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let formParams: { append(param: string, value: any): void | HttpParams };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (ticketCode !== undefined) {
      formParams = formParams.append('ticketCode', <any>ticketCode) || formParams;
    }

    return this.httpClient.post<RoomGuest>(
      `${this.basePath}/checkin`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Checkout guest
   *
   * @param ticketCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkoutGuest(ticketCode: string, observe?: 'body', reportProgress?: boolean): Observable<RoomGuest>;
  public checkoutGuest(
    ticketCode: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RoomGuest>>;
  public checkoutGuest(
    ticketCode: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RoomGuest>>;
  public checkoutGuest(ticketCode: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (ticketCode === null || ticketCode === undefined) {
      throw new Error('Required parameter ticketCode was null or undefined when calling checkoutGuest.');
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let formParams: { append(param: string, value: any): void | HttpParams };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (ticketCode !== undefined) {
      formParams = formParams.append('ticketCode', <any>ticketCode) || formParams;
    }

    return this.httpClient.post<RoomGuest>(
      `${this.basePath}/checkout`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}

import { CateringService } from './service/catering.service';
import { CheckinCheckoutService } from './service/checkinCheckout.service';
import { GeneralService } from './service/general.service';
import { RoomService } from './service/room.service';
import { RoomDeviceService } from './service/roomDevice.service';

export * from './service/catering.service';
export * from './service/checkinCheckout.service';
export * from './service/general.service';
export * from './service/room.service';
export * from './service/roomDevice.service';

export const APIS = [CateringService, CheckinCheckoutService, GeneralService, RoomService, RoomDeviceService];

/**
 * SpaServer
 * SpaServer
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Status object returned by API. the status propertie replicates the status code of the response
 */
export interface StatusModel {
  /**
   * Status of the Response
   */
  code: number;
  /**
   * Description what happend
   */
  message: string;
  /**
   * sentry event id
   */
  event?: string;
  /**
   * public sentry dsn
   */
  dsn?: string;
  /**
   * details about the error
   */
  details?: { [key: string]: string };
}

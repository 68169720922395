/**
 * SpaServer
 * SpaServer
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Room {
  id?: string;
  name?: string;
  state?: RoomState;
}

export type RoomState = 'EMPTY' | 'OCCUPIED' | 'DIRTY' | 'CLEANING' | 'BROKEN';
export const RoomState = {
  EMPTY: 'EMPTY' as RoomState,
  OCCUPIED: 'OCCUPIED' as RoomState,
  DIRTY: 'DIRTY' as RoomState,
  CLEANING: 'CLEANING' as RoomState,
  BROKEN: 'BROKEN' as RoomState,
};

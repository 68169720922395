/**
 * SpaServer
 * SpaServer
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RoomTV {
  status?: RoomTVStatus;
  input?: RoomTVInput;
}

export type RoomTVStatus = 'ON' | 'OFF';
export const RoomTVStatus = {
  ON: 'ON' as RoomTVStatus,
  OFF: 'OFF' as RoomTVStatus,
};

export type RoomTVInput = 'XBOX' | 'CHROMECAST';
export const RoomTVInput = {
  XBOX: 'XBOX' as RoomTVInput,
  CHROMECAST: 'CHROMECAST' as RoomTVInput,
};

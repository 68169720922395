/**
 * SpaServer
 * SpaServer
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ProductAdditives } from './productAdditives';
import { ProductAllergens } from './productAllergens';
import { ProductGroup } from './productGroup';

export interface Product {
  id?: string;
  name?: string;
  description?: string;
  ean?: string;
  price?: number;
  taxValue?: number;
  imageUrl?: string;
  group?: ProductGroup;
  additives?: Array<ProductAdditives>;
  allergens?: Array<ProductAllergens>;
}

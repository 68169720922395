/**
 * SpaServer
 * SpaServer
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { RoomSession } from './roomSession';

export interface RoomGuest {
  id?: string;
  firstName?: string;
  lastName?: string;
  primaryGuest?: boolean;
  state?: RoomGuestState;
  session?: RoomSession;
}

export type RoomGuestState = 'CHECKEDIN' | 'CHECKEDOUT';
export const RoomGuestState = {
  CHECKEDIN: 'CHECKEDIN' as RoomGuestState,
  CHECKEDOUT: 'CHECKEDOUT' as RoomGuestState,
};

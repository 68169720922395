/* eslint-disable prefer-const */
/* tslint:disable:no-unused-variable member-ordering */

/**
 * SpaServer
 * SpaServer
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';

import { Order } from '../model/order';
import { Product } from '../model/product';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CateringService {
  protected basePath = environment.apiBasePath;

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get product
   *
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProduct(productId: string, observe?: 'body', reportProgress?: boolean): Observable<Product>;
  public getProduct(
    productId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Product>>;
  public getProduct(productId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Product>>;
  public getProduct(productId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error('Required parameter productId was null or undefined when calling getProduct.');
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Product>(`${this.basePath}/catering/product/${encodeURIComponent(String(productId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get products
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProducts(observe?: 'body', reportProgress?: boolean): Observable<Array<Product>>;
  public getProducts(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Product>>>;
  public getProducts(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Product>>>;
  public getProducts(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Array<Product>>(`${this.basePath}/catering/product`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * submit order
   *
   * @param order
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public submitOrder(order: Order, observe?: 'body', reportProgress?: boolean): Observable<Order>;
  public submitOrder(order: Order, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
  public submitOrder(order: Order, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
  public submitOrder(order: Order, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (order === null || order === undefined) {
      throw new Error('Required parameter order was null or undefined when calling submitOrder.');
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ['application/json'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Order>(`${this.basePath}/catering/order`, order, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}

import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[wellextSpaGlamourTint]',
})
export class GlamourTintDirective {
  constructor(private element: ElementRef) {
    this.element.nativeElement.style.color = '#957D4C';
    this.element.nativeElement.style.textDecorationColor = '#957D4C';
  }
}
